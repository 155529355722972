.user-filter-grid {
  margin-top: 10px;
  padding: 10px;
  color: black;
}

.search-input-text {
  color: #ffffff;
}

.supplier-form {
  margin-top: 10px;
  padding: 10px;
  border: 2px solid #0C4B60;
}

.supplier-form-label {
  float: right;
  padding-top: 9px;
  color: #0C4B60;
  padding-right: 10px;
}

.pop-up-form-label {
  float: left;
  padding-top: 9px;
  color: #0C4B60;
  padding-right: 10px;
}



.div-border-margin {
  border-style: solid;
  border-width: medium;
  border-color: #0c4b60;
  margin: 10px 0px 20px 0px;
}


.info-form-label {
  float: right;
  color: #4b9170 !important;
  font-size: 8px;
  padding: 0px !important;
  margin-top: -10px !important;
  margin-bottom: 10px !important;
}


.quickIconPadding {
 padding: 0px 5px 0px 0px !important;
}

.quickLinkHomeIconMargin {
  margin: 0px 5px -3px 0px !important;
 }