.grid-container {
  padding: 0px 20px 20px 20px;
}

.header {
  padding: 10px;
  /* background-color: #3f51b5;
  color: #fff; */
}

.loginImage{
  width: 240px;
  height: 120px;
}


.headerLogo{
  width: 45px;
  height: 45px;
}
