
.recordSaleInvoiceLabel {
  font-size: medium;
  color: #0C4B60;
}

.recordSaleInvoiceHeader {
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: #0c4b60;
  padding:0px 5px;
  color: #ffffff;
}

.recordSaleInvoiceBody{
  padding: 10px;
}