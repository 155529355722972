.sigCanvas {
  border: 1px solid black;
  cursor: crosshair;
}

.sigErrorCanvas {
  border: 1px solid red;
  cursor: crosshair;
}

.machineErrorCanvas {
  border: 1px solid red;
}

.machineNoErrorCanvas {
  border: 1px solid black;
}

.user-filter-grid {
  margin-top: 10px;
  padding: 10px;
  color: black;
}

.search-input-text {
  color: #ffffff;
}
