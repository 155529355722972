.user-filter-grid {
  margin-top: 10px;
  padding: 10px;
  color:black;
}
 
.customer-form {
  margin-top: 10px;
  padding: 10px;
  border: 2px solid #0C4B60;
}

.customer-form-label {
  float: right;
  padding-top: 9px;
  color: #0C4B60;
  padding-right: 10px;
}

.search-input-text {
  color: #ffffff;
}


.customer-edit-form {
  margin-top: 10px;
  padding: 10px;
}


.customer-edit-form-label {
  color: #0C4B60;
}


.text-sub-label {
  font-size: smaller;
  color: #0C4B60;
}

.text-main-label {
  font-size: large;
  color: black;
}