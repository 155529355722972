.login-content {
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.body-content {
  min-height: calc(100vh - 120px);
  /* padding-bottom for the footer */
  padding: 10px 5px 20px 5px;
}

.footer {
  height: 20px;
  width: 100%;
  text-align: center;
  bottom: 0;
  font-size: 12px;
  color: grey;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dashboard-grid-container {
  min-height: inherit;
  display: flex;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.filter-grid {
  margin-top: 10px;
  padding: 10px;
  background-color: #4b9170;
  color: #ffffff;
}

.search-input-text {
  color: #ffffff;
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  @page {
    size: A4 portrait;
    margin: 5%;
  }
}

.dailyLabelHeadr{
  font-size: 20px;
  color: #0C4B60;
  font-weight: bold;
}

.dailyJobIcon{
  width: 40px;
  height: 40px;
  float: right;
}

.dailyJobMainBody{
  padding: 15px;
}

.dailyJobContentMainBody{
  width: 100%;
  margin: 0px;
  padding: 0px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.151) !important;
  -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.151) !important;
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.151) !important;
  -khtml-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.151) !important;
}
.dailyJobContentSubBody{
  padding: 10px;
}
.errorMsg{
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 3 !important;
  height: 72px;
  overflow: hidden;
}

.btnBody{
  padding-top: 15px;
}

.loader{
  z-index: 999;
  position: absolute;
  left: 50%;
  bottom: 10%;
  height: max-content;
}

.errorMsgTl{
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  overflow: hidden;
}

.paddingStyle{
  padding:6px 0px 6px 16px;
}

.dateTlStyle{
  padding:6px 0px 6px 16px;
  /* min-width: 120px; */
}

.underLine{
  text-decoration: none !important;
}
.underLine:hover{
text-decoration: underline !important;
}